import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

const rawTheme = createMuiTheme({
  typography: {
    fontFamily: [
      '"Helvetica Neue"',
    ].join(','),
    color: 'white',
  },
  palette: {
    text: {
      primary: '#D5D5D5',
      secondary: '#917C63'
    },
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#917C63',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#2B2B2B'
    }
  },
});

const theme = {
  ...rawTheme
}

export default theme;
