import firebase from 'firebase/app';
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBuB6UkElKNouUtq2THbxrjfHvLy8m7jjQ",
  authDomain: "luc-peirolo.firebaseapp.com",
  databaseURL: "https://luc-peirolo.firebaseio.com",
  projectId: "luc-peirolo",
  storageBucket: "luc-peirolo.appspot.com",
  messagingSenderId: "1024546513535",
  appId: "1:1024546513535:web:99e921dd26bf34f304d54a"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

export const sendMessage = ({ body, email, tel, prenom, nom }) => {
  return db.collection('messages')
    .add({
      body,
      email,
      tel,
      prenom,
      nom,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
};
