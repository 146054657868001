import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4)
  },
  subtitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  }
});

function Agence(props) {
  const { classes } = props;

  return (
    <Container maxWidth="md" className={classes.root} component="section">
      <Typography variant="h4" component="h2" color="textSecondary">
        <Box fontWeight="fontWeightMedium" letterSpacing={4}>
          AGENCE
        </Box>
      </Typography>
      <Typography variant="h5" color="textPrimary" className={classes.subtitle}>
        <Box fontWeight="fontWeightRegular" letterSpacing={6}>
          QUI SOMMES NOUS?
        </Box>
      </Typography>
      <Typography variant="body1" color="textPrimary" className={classes.textDescription}>
        Depuis sa création en 1991, l’agence a pour ambition de développer une expertise pluridisciplinaire dans l’aménagement urbain diurne, nocturne et durable.
      </Typography>
      <Typography variant="body1" color="textPrimary" className={classes.textDescription}>
        Aménager une ville où il fait bon vivre, réinterroger le territoire à différente échelles : de la rue au grand paysage, rendre la ville de jour comme de nuit aux usagers, afin de l’inscrire dans une dynamique urbaine, valoriser les territoires dans une dimension favorable à la transition écologique, constituent les ambitions et les savoir-faire de l’agence.
      </Typography>
      <Typography variant="h5" color="textPrimary" className={classes.subtitle}>
        <Box fontWeight="fontWeightRegular" letterSpacing={6}>
          NOTRE SAVOIR-FAIRE
        </Box>
      </Typography>
      <Typography variant="body1" color="textPrimary" className={classes.textDescription}>
        L’agence intervient à tous les stades de définition des projets de ville et de territoires : stratégie, concertation, programmation, faisabilité, conception, suivi de réalisation, en maîtrise d’œuvre ou en assistance maîtrise d’ouvrage, avec une approche intégrée du développement durable.
      </Typography>
      <Typography>
        L’agence invente, développe et met en œuvre des solutions créatives et durables dans les domaines de l’architecture, de l’urbanisme lumière, de la conception lumière.
      </Typography>
    </Container>
  );
}

Agence.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Agence);
