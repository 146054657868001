import caen1 from './static/caen1.jpg';
import caen2 from './static/caen2.jpg';
import caen3 from './static/caen3.jpg';
import caen4 from './static/caen4.jpg';
import caen5 from './static/caen5.jpg';
import caen6 from './static/caen6.jpg';
import caen7 from './static/caen7.jpg';
import caen8 from './static/caen8.jpg';
import lavardin1 from './static/lavardin1.jpg';
import lavardin2 from './static/lavardin2.jpg';
import lavardin3 from './static/lavardin3.jpg';
import lavardin4 from './static/lavardin4.jpg';
import lavardin5 from './static/lavardin5.jpg';
import universite1 from './static/universite1.jpg';
import universite2 from './static/universite2.jpg';
import universite3 from './static/universite3.jpg';
import parking1 from './static/parking1.jpg';
import parking2 from './static/parking2.jpg';
import parking3 from './static/parking3.jpg';
import parking4 from './static/parking4.jpg';
import parking5 from './static/parking5.jpg';
import parking6 from './static/parking6.jpg';
import parking7 from './static/parking7.jpg';
import parking8 from './static/parking8.jpg';
import parking9 from './static/parking9.jpg';
import theatre1 from './static/theatre1.jpg';
import theatre2 from './static/theatre2.jpg';
import stcloud1 from './static/stcloud1.jpg';
import stcloud2 from './static/stcloud2.jpg';
import station1 from './static/station1.jpg';
import station2 from './static/station2.jpg';
import station3 from './static/station3.jpg';
import station4 from './static/station4.jpg';
import solendi1 from './static/solendi1.jpg';
import solendi3 from './static/solendi3.jpg';
import solendi4 from './static/solendi4.jpg';
import solendi5 from './static/solendi5.jpg';
import usine1 from './static/usine1.jpg';
import usine2 from './static/usine2.jpg';
import usine3 from './static/usine3.jpg';
import usine4 from './static/usine4.jpg';
import unite1 from './static/unite1.jpg';
import unite2 from './static/unite2.jpg';
import unite3 from './static/unite3.jpg';
import unite4 from './static/unite4.jpg';

const projects = [
  {
    id: 'chateau_caen',
    title: 'CHÂTEAU DE CAEN',
    subtitle: 'Mise en lumière du patrimoine et des espaces publics',
    intro: 'Mise en lumière des remparts, de la tour Puchot à la tour Mathilde.',
    descriptions: [
      "Le projet de mise en lumière s’inscrit dans une dimension globale de mise en valeur nocturne et environnementale du site. La mise en scène interroge les couches successives de l’histoire qui font du site un lieu de mémoire et de refondation d’une nouvelle urbanité.",
      "Le projet joue d’un paradoxe : éclairer le rempart tout en le plongeant dans le bleu de la nuit, de ses ombres, de ses mystères, pour en révéler les traces visibles ou invisibles, les histoires connues, cachées ou oubliées, jusqu’au moment où, au bleu de la nuit succèdent les blancs et les ors du jour qui éclairent d’autres usages, d’autres histoires.",
      "Au pied du rempart, le projet concilie « trame noire » et flânerie romantique, au fil de la promenade tout est tableau, jeu de couleurs, effets d’ombres et de lumières, de clairs obscurs. La scénographie plonge l’espace du fossé dans une abîme théâtrale, toute de rouge éclairée au pied de la barbacane de la Porte Saint Pierre, scène ou se rejoue l’expérience du rempart.",
      "Les installations sont réversibles et non intrusives ; en supprimant les pollutions lumineuses afférentes à la lumière le projet participe à consolider l’intégrité physique, visuelle et patrimoniale du monument, de son socle.",
      "Les technologies et matériels d’éclairage utilisés (LED), le pilotage de l’ensemble des installations permet d’optimiser les coûts d’exploitation et de maintenance. La puissance installée est 3 fois inférieure aux ratios habituels."
    ],
    images: [
      caen3,
      caen1,
      caen2,
      caen4,
      caen5,
      caen6,
      caen7,
      caen8
    ],
    ouvrages: [
      'Ville de Caen',
    ],
    oeuvres: [
      'Luc Peirolo & Associés Architectes',
    ],
    programmes: [
      'Mise en lumière des remparts extérieurs Tour Puchot / Tour Mathilde et éclairage public de la butte Saint Pierre',
    ],
    date: '2018/2019',
    cost: {
      header: 'Montant travaux éclairage',
      price: '396 273 euros HT'
    }
  },
  {
    id: 'chateau_lavardin',
    title: 'CHÂTEAU DE LAVARDIN',
    subtitle: 'Mise en lumière du patrimoine, Lavardin "Les plus beaux villages de France"',
    intro: 'Mise en lumière du chateau visant à mettre en scène « l’incomplétude de la ruine » et sa charge poétique.',
    descriptions: [
      'Le projet vise à mettre en scène « l’incomplétude de la ruine » et sa charge poétique.',
      'Les différents tableaux donnent à lire une présence, une histoire, à stimuler notre imagination sans vouloir tenter une recomposition descriptive.',
      'La mise en scène propice à la flânerie, à la rêverie, favorise la promenade ; certains tableaux ou panoramas privilégient les vues proches, d’autres les vues lointaines pour inscrire le projet à la fois dans l’expérience du site et dans le grand paysage.',
    ],
    images: [
      lavardin1,
      lavardin2,
      lavardin3,
      lavardin4,
      lavardin5
    ],
    ouvrages: [
      'Mairie de Lavardin'
    ],
    oeuvres: [
      'Luc Peirolo & Associés Architectes',
      'BET/ ALTO Ingénierie',
    ],
    programmes: [
      "Diagnostic de l'état des lieux",
      'Plan lumière / SDAL',
      'Mission de maitrise d’œuvre complète pour la mise en lumière du château de Lavardin',
    ],
    date: '2006/2007',
    cost: {
      header: 'Montant travaux éclairage',
      price: '110 000 euros HT'
    }
  },
  {
    id: 'universite_nanterre',
    title: 'UNIVERSITÉ PARIS NANTERRE',
    subtitle: 'Plan Eco-lumière. Mise en lumière urbaine, architecturale et paysagère',
    intro: 'Amélioration de la qualité du cadre de vie nocturne du campus.',
    descriptions: [
      "Dans le cadre d’une action expérimentale intitulée « Plan Eco-lumière pour les campus », EDF, le ministère de l’éducation nationale, l’université Paris Nanterre ont souhaité améliorer la qualité du cadre de vie nocturne du campus.",
      "Ce territoire de 30 hectares situé entre réseaux d’infrastructures routières et délaissés urbains en cours de réaménagement accueille 35 000 étudiants, 3 000 chercheurs-enseignants, personnels administratifs et techniques, il constitue une enclave urbaine en rupture avec la ville alentours.",
      "Les objectifs du Plan Eco-lumière visent à :",
      "* Améliorer l’insertion du campus dans la ville par le traitement des espaces de transition et interfaces ; faire le lien avec le paysage nocturne dans les « temps de la ville » comme dans « les temps de la nuit »;",
      "* Donner ou redonner du sens, de la lisibilité, une nouvelle identité à un territoire universitaire dans la ville. Mettre en valeur ses patrimoines architecturaux et naturels.",
      "* Améliorer la sécurité et l’attractivité nocturne, favoriser le confort, l’accessibilité, la mobilité pour tous les usagers, mettre en scène une nouvelle qualité de vie, une qualité urbaine durable.",
      "* Concilier urbanité et protection de la biodiversité, « trame noire » et éclairage, limiter l’impact environnemental du projet.",
      "Au travers des études réalisées le projet vise à :",
      "* Une meilleure connaissance du parc éclairage.",
      "* Définir la politique générale d’éclairage des espaces du campus en conformité avec les réglementations en vigueur, afin d’harmoniser les choix d’aménagement, d’investissement et d’entretien du matériel.",
      "* Établir les conditions de mise en œuvre de la politique d’éclairage au regard de la situation, l’évolution des usages et des mutations urbaines en cours, des exigences environnementales.",
      "* Réduire les nuisances lumineuses, les consommations énergétiques, les rejets de cO2.",
      "* Réduire et optimiser les coûts d’exploitation, de gestion et de maintenance."
    ],
    images: [
      universite1,
      universite2,
      universite3
    ],
    ouvrages: [
      'Ministère de l’éducation nationale',
      'Université Paris Nanterre / Direction du patrimoine',
    ],
    oeuvres: [
      'Concepteur lumière / Luc Peirolo & Associés Architectes',
      'BETEM Ingénierie',
    ],
    programmes: [
      'Diagnostic technique et qualitatif',
      'Plan lumière / SDAL',
      'Mission de maitrise d’œuvre pour l’éclairage EP, 1ère, 2ème et 3ème tranches de travaux, 4ème tranche (2021/2024)',
    ],
    date: '2005/- En cours',
    cost: {
      header: 'Montant travaux éclairage',
      price: '1ère et 2ème tranche: 740 000 euros HT, 3ème tranche: 800 000 euros HT'
    }
  },
  {
    id: 'parking_defense',
    title: 'PARKINGS',
    subtitle: 'Éclairage architectural et fonctionnel',
    intro: "Eclairage architectural et fonctionnel. Création d'une identité spécifique, mettant en avant l'image et l'usage des parkings du quartier de la Défense.",
    descriptions: [
      "Contribuant à la mise en valeur du quartier de la Défense, les parcs de stationnement Corolles et Reflets situés sous l’esplanade, mettent en scène leurs différents niveaux par une identité visuelle et lumineuse assurant sécurité et confort pour les usagers. La signalétique peinte et lumineuse joue le rôle de fil conducteur, permettant de se repérer facilement dans l’espace. Chaque étage est identifié suivant une identité chromatique particulière",
      'Les technologies et matériels d’éclairage utilisés (LED), le pilotage de l’ensemble des installations permet d’optimiser les coûts d’exploitation et de maintenance. La puissance installée est de 2,5 inférieure aux ratios habituels.',
    ],
    images: [
      parking1,
      parking2,
      parking3,
      parking4,
      parking5,
      parking6,
      parking7,
      parking8,
      parking9,
    ],
    ouvrages: [
      'EPADESA',
    ],
    oeuvres: [
      'Concepteur lumière / Luc Peirolo & Associés Architectes',
      'Architecte / Sud Architecture',
      'BET / BETEM Ingénierie'
    ],
    programmes: [
      "Mission de maîtrise d’œuvre pour le réaménagement et de l'eclairage des parkings",
      'Surface : 60 000 m2'
    ],
    date: '2009/2014',
    cost: {
      header: 'Montant travaux',
      price: '25 M euros HT'
    }
  },
  {
    id: 'saint_cloud',
    title: 'MAISONS DE VILLE',
    subtitle: 'Conception bioclimatique, label H&E.',
    intro: "Construction de 4 maisons de ville groupées. Conception bioclimatique, label H&E.",
    descriptions: [
      "4 maisons de ville à l’architecture bioclimatique:",
      '* Thermique d’hiver : Orientation plein sud pour capter la chaleur et la lumière du soleil à l’aide de fenêtres et baies vitrées à triple vitrage.',
      '* Thermique d’été : Larges balcons comme espaces de translation climatique et protection par débord de toiture - brises soleil verticaux pour contrôler-réduire les apports de calories.',
      '* Pièces à vivre au sud, pièces de service au nord.',
      '* Utilisation de végétaux et arbres de hautes tiges au sud pour créer de l’ombre et apporter de la fraicheur en été.',
      '* Construction compacte sur 2 étages pour diminuer les déperditions thermiques.',
      '* Utilisation de matériaux à « forte inertie » et ossature bois.',
      '* Ventilation naturelle pour réduire les consommations énergétiques, améliorer la qualité de l’air intérieur et du confort hygrométrique en été.',
      'Publications – Expositions:',
      '* Acte Sud et cité de l’Architecture et du Patrimoine : Quelles architectures pour une ville durable?',
      'Connaissance des Arts : Habiter écologique – HS n°404 mai 2009',
      'Ekopolis : Film sur les maisons de ville groupées HE à saint Cloud',
      'Project sélectionné par:',
      '* L’observatoire de la qualité architectural – URCAUE IDF',
      '* Exposition 2009 Cité de l’architecture « HABITER ECOLOGIQUE Quelles architectures pour la ville durable – Les pratiques actuelles : douze projets écoresponsables »',
      '* Actes Sud – Cité de l’architecture et du patrimoine'
    ],
    images: [
      stcloud1,
      stcloud2
    ],
    ouvrages: [
      'SAGEP - Eau de Paris',
    ],
    oeuvres: [
      'Architecte / Luc Peirolo & Associés Architectes / ALP CAR',
      'BET / ALTO Ingénierie'
    ],
    programmes: [
      "Mission de maîtrise d’œuvre pour la construction de 4 maisons de ville groupées",
      'Surface : 668 m2'
    ],
    date: '2006/2007',
    cost: {
      header: 'Montant travaux',
      price: '1 085 000 euros TTC'
    }
  },
  {
    id: 'theatre_nanterre',
    title: 'THÉÂTRE UNIVERSITAIRE NANTERRE',
    subtitle: 'Éclairage fonctionnel',
    intro: 'Restructuration partielle du théâtre universitaire, avec mise en conformité, amélioration des performances énergétiques et acoustiques.',
    descriptions: [
      "Le programme de restructuration partielle du théâtre universitaire prévoit une mise en conformité technique et réglementaire des espaces en termes d’accessibilité et de sécurité incendie, une amélioration des performances énergétiques et acoustiques du bloc salle-scène, une amélioration fonctionnelle d’une partie de l’équipement afin de l’adapter aux nouvelles pratiques de création, d’enseignement et de diffusion su spectacle vivant.",
      'L’opération porte sur la reconfiguration architecturale et technique du bloc salle-scène (plateau et scène, gradinage, loges, grill technique, plafond acoustique) des loges techniques, régie, des salles de cours, des locaux techniques, réserves et annexes.',
      'L’éclairage de la salle et de la scène utilise les nouvelles technologies de gradation LED qui permettent d’ajuster les niveaux d’éclairement et la couleur de la lumière aux besoins de l’éclairage de théâtre. Le remplacement des lampes traditionnelles par la LED permet d’abaisser de 4 fois les consommations électriques et les apports caloriques, tout en limitant au maximum les opérations de maintenance grande hauteur.'
    ],
    images: [
      theatre1,
      theatre2
    ],
    ouvrages: [
      'Université Paris Nanterre / Direction du patrimoine',
    ],
    oeuvres: [
      'Concepteur lumière / Luc Peirolo & Associés Architectes',
      'Architecte / ARCHIDEV',
      'BET fluides & thermique / GMGB',
      'BET Structure / AR-C'
    ],
    programmes: [
      'Diagnostic & mission de maîtrise d’œuvre pour le réaménagement et la mise aux normes réglementaires du théâtre Bernard Marie Koltès - Bâtiment L',
    ],
    date: '2017/2019',
    cost: {
      header: 'Montant travaux éclairage',
      price: '256 063 Euros HT '
    }
  },
  {
    id: 'unite-industrielle',
    title: 'PROJETS INDUSTRIELS',
    subtitle: 'Réalisation d’un bâtiment technique « pilote »',
    intro: 'Projet visant à réduire l’empreinte carbone, à optimiser les consommations d’énergie, tout en valorisant le biogaz issu du co-traitement des eaux usées',
    descriptions: [
      "Le projet pilote Cometha réalisé en partenariat d’innovation par le SYCTOM et le SIAAP, s’inscrit dans un plan d’actions commun visant à réduire l’empreinte carbone, à optimiser les consommations d’énergie, tout en valorisant le biogaz issu du co-traitement des eaux usées du SIAAP et de la fraction organique des ordures ménagères résiduelles du Syctom.",
      'L’unité pilote industrielle est implantée sur le site du SIAAP à Seine-Amont, Valenton Val-de-Marne (94). Elle constitue la première installation Francilienne de co-méthanisation utilisant des intrants provenant du traitement des eaux usées et des déchets ménagers. Elle cible la méthanisation comme moyen de valorisation des boues issues de la dépollution des eaux usées, et de la fraction organique résiduelle provenant des ordures ménagères. Elle a pour objectif de les réduire en volume puis les convertir en bio-méthane. Le projet est mené par le groupement européen qui associé les sociétés John Cockerill et Sources.',
      'Les enjeux environnementaux du projet visent à réduire l’empreinte environnementale du projet sur le milieu dans lequel il s’inscrit : biodiversité et image du grand paysage. L’objectif vise à fondre l’équipement dans son environnement en jouant sur la sobriété/simplicité des volumes et des percements, la réflexibilité (effet miroir) des façades revêtues d’inox poli miroir qui offre une façade toujours changeante en fonction des heures du jour, de la nuit, de la lumière, des saisons.',
    ],
    images: [
      unite1,
      unite2,
      unite3,
      unite4
    ],
    ouvrages: [
      'Syctom / SIAA',
    ],
    oeuvres: [
      'Luc Peirolo & Associés Architectes',
      'ALP CAR Architectes',
      'Conception / JOHN COKERILL PROSERPOL'
    ],
    programmes: [
      "Conception architecturale limitée aux phases APS/APD/PC"
    ],
    date: '2020-',
    cost: {
      header: 'Montant travaux',
      price: '1,2 M euros TTC'
    }
  },
  {
    id: 'usine',
    title: 'SITES INDUSTRIELS',
    subtitle: 'Modernisation et évolution de la filière traitement de l’usine de production d’eau potable d’Orly',
    intro: 'Modernisation et évolution de la filière traitement de l’usine de production d’eau potable d’Orly',
    descriptions: [
      "Projet visant à:",
      '* Valoriser un aménagement et une architecture industrielle durable au sein d’un site paysager et naturel sensible, en lien avec les projets urbains en devenir sur le territoire.',
      '* Diminuer l’impact des aménagements, équipements, bâtiments industriels sur la biodiversité.',
    ],
    images: [
      usine1,
      usine2,
      usine3,
      usine4
    ],
    ouvrages: [
      'Eau de Paris',
    ],
    oeuvres: [
      'Luc Peirolo & Associés Architectes',
      'ALP CAR Architectes'
    ],
    programmes: [
      "Assistance maîtrise d’ouvrage"
    ],
    date: '2019',
    cost: {
      header: 'Montant travaux',
      price: '48 M euros TTC'
    }
  },
  {
    id: 'rosny_sur_seine',
    title: 'STATION D’ÉPURATION',
    subtitle: 'Reconstruction d’une STEP dans une démarche environnementale',
    intro: 'Restructuration globale du site et de ses équipements afin de les adapter aux normes environnementales et aux évolutions démographiques.',
    descriptions: [
      "Le projet s’inscrit dans une restructuration globale du site et de ses équipements afin de les adapter aux normes environnementales et aux évolutions démographiques (capacité de traitement).",
      'Des solutions techniques innovantes, une approche paysagère et architecturale durable, permettent à la nouvelle station d’épuration de s’intégrer dans l’environnement naturel et sensible des bords de Seine (trame verte et trame bleue) à proximité d’un éco-quartier.',
      'Une intégration architecturale harmonieuse. La station est conçue comme un modèle d’intégration paysagère : architectures douces, matériaux naturels comme le bois, habillages en gabion et façades végétalisées.',
      'Les façades « filtres » ou à effet d’écran translucide s’ouvrent sur les fronts paysagés afin de:',
      '* Valoriser l’identité visuelle du projet',
      '* Favoriser l’inscription des équipements industriels dans l’environnement paysager.',
      'La mise en lumière des équipements et du site favorise une « trame noire » et joue d’une scénographie où ombres et lumières sont filtrées pour laisser toute sa place à la nuit.',
      'Préservation de la biodiversité. Une attention particulière a été portée à la préservation des milieux naturels : espaces boisés, prairies humides, de la flore et de la faune endémique. L’introduction d’essences végétales locales et variées permet de renforcer les corridors biologiques. La plantation de prairies de graminées adaptées aux contraintes du changement climatique permet de limiter les opérations d’entretien et améliore la durabilité des aménagements.'
    ],
    images: [
      station1,
      station2,
      station3,
      station4
    ],
    ouvrages: [
      'CAMY (Communauté d’Agglomération de Mantes en Yvelines) - Rosny sur Seine (78)',
    ],
    oeuvres: [
      'Architecte / Luc Peirolo & Associés Architectes',
      'OTV France',
      'EGIS EAU / PINTO'
    ],
    programmes: [
      'Reconstruction de la station d’épuration de la CAMY à Rosny sur Seine',
      'Étude de maîtrise d’œuvre pour la réalisation d’une STEP d’une capacité équivalente à 142 000 habitants.'
    ],
    date: '2010/2013',
    cost: {
      header: 'Montant travaux',
      price: '45 M euros HT'
    }
  },
  {
    id: 'solendi',
    title: 'SIÈGE SOCIAL',
    subtitle: 'Space planning / Aménagement de bureaux / Éclairage naturel et artificiel',
    intro: "Aménagement intérieur des bureaux, conception et réalisation de l'éclairage.",
    descriptions: [
      "Au plus près des nouvelles approches en matière d’organisation des espaces de travail, le projet offre toute la flexibilité requise au partage de l’espace pour un travail individuel ou de groupe, pour des réunions programmées ou des discussions informelles autour d’un café.",
      'Adaptabilité, modularité, flexibilité, évolutivité, constituent les caractéristiques spatiales et techniques du projet.',
      'L’éclairage naturel est optimisé pour tous les espaces. L’éclairage artificiel associe flexibilité et performance énergétique, pilotable et modulable il offre un confort visuel élevé.'
    ],
    images: [
      solendi1,
      solendi3,
      solendi4,
      solendi5
    ],
    ouvrages: [
      'SODEARIF - SOGEPROM',
    ],
    oeuvres: [
      'Aménagement et éclairage / Luc Peirolo & Associés Architectes',
      'Architecte / MAUFRAS & associés',
      'BET / ALTO Ingénierie'
    ],
    programmes: [
      'Étude de programmation et de space planning / MOE complète pour l’aménagement intérieur',
      'SHON : 7 100 m2 + 100 emplacements de parking'
    ],
    date: '2010/2013',
    cost: {
      header: 'Montant travaux',
      price: '14,2 M euros HT'
    }
  },
];

export default projects;
