import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ProjetItem from './ProjetItem';
import projects from './ProjectData';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4)
  },
  mainGrid: {
    marginTop: theme.spacing(5),
  },
});

function Projets(props) {
  const { classes } = props;

  return (
    <Container maxWidth="md" className={classes.root} component="section">
      <Typography variant="h4" component="h2" color="textSecondary">
        <Box fontWeight="fontWeightMedium" letterSpacing={4}>
          PROJETS
        </Box>
      </Typography>
      <Grid container className={classes.mainGrid}>
        {projects.map((project) => (
          <ProjetItem key={project.id} project={project} />
        ))}
      </Grid>
    </Container>
  );
}

Projets.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Projets);
