import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import projects from './ProjectData';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4)
  },
  mainGrid: {
    marginTop: theme.spacing(5),
  },
  slider: {
    marginBottom: theme.spacing(4),
  },
  imageContainer: {
    position: 'relative',
    display: 'block',
    paddingBottom: '70%',
    height: 0,
    overflow: 'hidden',
  },
  slickImage: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  },
  title: {
    marginTop: theme.spacing(6),
  },
  subtitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  description: {
    marginBottom: theme.spacing(1),
  },
  ouvrage: {
    marginTop: theme.spacing(3)
  }
});

function ProjetDetail(props) {
  const { classes } = props;
  const { projetId } = useParams();

  const projet = projects.find(({ id }) => id === projetId);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false
  };

  if (projet) {
    return (
      <Container maxWidth="md" className={classes.root} component="section">
        <Slider {...settings} className={classes.slider}>
          {projet.images.map(image => (
            <div className={classes.imageContainer}>
              <div
                className={classes.slickImage}
                style={{
                  backgroundImage: `url(${image})`,
                }}
              />
            </div>
          ))}
        </Slider>
        <Typography variant="h4" component="h2" color="textSecondary" className={classes.title}>
          <Box fontWeight="fontWeightMedium" letterSpacing={4}>
            {`${projet.title}`}
          </Box>
        </Typography>
        <Typography variant="h6" color="textPrimary" className={classes.subtitle}>
          <Box fontWeight="fontWeightRegular" letterSpacing={2}>
            {`${projet.subtitle}`}
          </Box>
        </Typography>
        {projet.descriptions.map(description => (
          <Typography variant="body1" color="textPrimary" className={classes.description}>
            {`${description}`}
          </Typography>
        ))}
        <Typography variant="h6" color="textSecondary" className={classes.ouvrage}>
          <Box fontWeight="fontWeightMedium" letterSpacing={1}>
            Maitre d’ouvrage
          </Box>
        </Typography>
        {projet.ouvrages.map(ouvrage => (
          <Typography variant="body1" color="textPrimary" className={classes.description}>
            {`${ouvrage}`}
          </Typography>
        ))}
        <Typography variant="h6" color="textSecondary" className={classes.ouvrage}>
          <Box fontWeight="fontWeightMedium" letterSpacing={1}>
            Maitre d’œuvre
          </Box>
        </Typography>
        {projet.oeuvres.map(oeuvre => (
          <Typography variant="body1" color="textPrimary" className={classes.description}>
            {`${oeuvre}`}
          </Typography>
        ))}
        <Typography variant="h6" color="textSecondary" className={classes.ouvrage}>
          <Box fontWeight="fontWeightMedium" letterSpacing={1}>
            Programme
          </Box>
        </Typography>
        {projet.programmes.map(programme => (
          <Typography variant="body1" color="textPrimary" className={classes.description}>
            {`${programme}`}
          </Typography>
        ))}
        <Typography variant="h6" color="textSecondary" className={classes.ouvrage}>
          <Box fontWeight="fontWeightMedium" letterSpacing={1}>
            Date
          </Box>
        </Typography>
        <Typography variant="body1" color="textPrimary" className={classes.description}>
          {`${projet.date}`}
        </Typography>
        <Typography variant="h6" color="textSecondary" className={classes.ouvrage}>
          <Box fontWeight="fontWeightMedium" letterSpacing={1}>
            Montant travaux
          </Box>
        </Typography>
        <Typography variant="body1" color="textPrimary" className={classes.description}>
          {`${projet.cost.price}`}
        </Typography>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="md" className={classes.root} component="section">
        <Typography variant="h4" component="h2" color="textSecondary">
          <Box fontWeight="fontWeightMedium" letterSpacing={4}>
            PROJET MANQUANT
          </Box>
        </Typography>
      </Container>
    );
  }
}

ProjetDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProjetDetail);
