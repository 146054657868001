import * as React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

const styles = (theme) => ({
  container: {
    marginBottom: theme.spacing(8),
  },
  imageWrapper: {
    position: 'relative',
    display: 'block',
    paddingBottom: '60%',
    [theme.breakpoints.up('md')]: {
      paddingBottom: '35%',
    },
    height: 0,
    overflow: 'hidden',
    cursor: 'pointer'
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(8),
    },
  },
  textDescription: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  textTitle: {
    cursor: 'pointer',
    textDecoration: 'none',
  },
});

function ProjetItem(props) {
  const { classes, project } = props;
  const { id } = project;

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <RouterLink to={`/projets/${id}`} ref={ref} {...itemProps} />),
    [id],
  );

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} md={6} className={classes.imageWrapper} component={renderLink}>
        <div
          className={classes.imageSrc}
          style={{
            backgroundImage: `url(${project.images[0]})`,
          }}
        />
      </Grid>
      <Grid item xs={12} md={6} className={classes.textWrapper}>
        <Typography variant="h5" color="textSecondary" className={classes.textTitle} component={renderLink}>
          <Box fontWeight="fontWeightMedium" letterSpacing={4}>
            {project.title}
          </Box>
        </Typography>
        <Typography variant="body1" color="textPrimary" className={classes.textDescription}>
          {project.intro}
        </Typography>
      </Grid>
    </Grid>
  );
}

ProjetItem.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    intro: PropTypes.string.isRequired,
    images: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(ProjetItem);
