import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Navbar from './Navbar';
import Footer from './Footer';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
});

function CoreLayout(props) {
  const { children, classes } = props;

  return (
    <div className={classes.root}>
      <Container maxWidth="md" className={classes.container}>
        <Navbar />
        {children}
      </Container>
      <Footer />
    </div>
  );
}

CoreLayout.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CoreLayout);
