import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import ScrollToTop from './ScrollToTop';
import CoreLayout from './CoreLayout';
import Home from './Home';
import Agence from './Agence';
import Projets from './Projets';
import ProjetDetail from './ProjetDetail';
import Contact from './Contact';

function App() {
  return (
    <React.Fragment>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path={["/"]}>
            <Switch>
              <Route exact path ="/">
                <Home />
              </Route>
            </Switch>
          </Route>
          <Route>
            <CoreLayout>
              <Switch>
                <Route path="/agence">
                  <Agence />
                </Route>
                <Route exact path='/projets'>
                  <Projets />
                </Route>
                <Route path={`/projets/:projetId`}>
                  <ProjetDetail />
                </Route>
                <Route path="/contact">
                  <Contact />
                </Route>
              </Switch>
            </CoreLayout>
          </Route>
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
