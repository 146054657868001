import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import * as FirestoreService from './firebase';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4)
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

function Contact(props) {
  const { classes } = props;
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const timer = React.useRef();
  const [bodyVal, changeBody] = useState('');
  const [emailVal, changeEmail] = useState('');
  const [telVal, changeTel] = useState('');
  const [nomVal, changeNom] = useState('');
  const [prenomVal, changePrenom] = useState('');

  const onBodyChange = e => changeBody(e.target.value);
  const onEmailChange = e => changeEmail(e.target.value);
  const onTelChange = e => changeTel(e.target.value);
  const onNomChange = e => changeNom(e.target.value);
  const onPrenomChange = e => changePrenom(e.target.value);

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const handleButtonClick = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      FirestoreService.sendMessage({
        body: bodyVal,
        email: emailVal,
        tel: telVal,
        nom: nomVal,
        prenom: prenomVal
      });
      timer.current = window.setTimeout(() => {
        setSuccess(true);
        setLoading(false);
      }, 500);
    }
  };

  return (
    <Container maxWidth="md" className={classes.root} component="section">
      <Typography variant="h4" component="h2" color="textSecondary">
        <Box fontWeight="fontWeightMedium" letterSpacing={4}>
          Contact
        </Box>
      </Typography>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="fname"
              name="firstName"
              variant="outlined"
              required
              fullWidth
              id="firstName"
              label="Prénom"
              autoFocus
              color="secondary"
              value={prenomVal}
              onChange={onPrenomChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="lastName"
              label="Nom"
              name="lastName"
              autoComplete="lname"
              color="secondary"
              value={nomVal}
              onChange={onNomChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="tel"
              label="Télephone"
              name="telephone"
              autoComplete="tel"
              type="tel"
              color="secondary"
              value={telVal}
              onChange={onTelChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Addresse Email"
              name="email"
              autoComplete="email"
              color="secondary"
              value={emailVal}
              onChange={onEmailChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="message"
              label="Message"
              multiline
              rows={4}
              id="message"
              color="secondary"
              value={bodyVal}
              onChange={onBodyChange}
            />
          </Grid>
        </Grid>
        <div className={classes.submit}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            disabled={loading}
            onClick={handleButtonClick}
          >
            {success ? 'Message Envoyé!' : 'Envoyer'}
          </Button>
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </form>
    </Container>
  );
}

Contact.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Contact);
