import React from 'react';
import Hero from './Hero';
import Selection from './Selection';
import Footer from './Footer';
import projects from './ProjectData';

const selected = projects.slice(0, 3);

function Home() {
  return (
    <React.Fragment>
      <Hero />
      <Selection projets={selected} />
      <Footer />
    </React.Fragment>
  );
}

export default Home;
