import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';
import { Link as RouterLink, useLocation} from 'react-router-dom';
import logo from './static/logo.png';

const styles = (theme) => ({
  toolbar: {
    width: '100%',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(3)
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
    '&:hover': {
      color: theme.palette.text.secondary,
    },
  },
  logo: {
    maxWidth: 120,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 150,
    },
  },
});

const sections = [
  { title: 'Agence', url: '/agence' },
  { title: 'Projets', url: '/projets' },
  { title: 'Contact', url: '/contact' },
];

function Navbar(props) {
  const { classes } = props;
  const { pathname } = useLocation();

  return (
    <Toolbar
      component="nav"
      variant="dense"
      className={classes.toolbar}
      >
      <Link component={RouterLink} to="/">
        <img src={logo} alt="logo" className={classes.logo} />
      </Link>
      {sections.map((section) => (
        <Link
          color={pathname === section.url ? `textSecondary` : `inherit`}
          component={RouterLink}
          to={section.url}
          variant="button"
          key={section.title}
          className={classes.toolbarLink}
        >
          {section.title}
        </Link>
      ))}
    </Toolbar>
  );
}

Navbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navbar);
