import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ProjetItem from './ProjetItem';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  mainGrid: {
    marginTop: theme.spacing(5),
  },
  subtitle: {
    marginTop: theme.spacing(2),
  },
  button: {
    display: 'flex',
    justifyContent: 'center'
  }
});

function Selection(props) {
  const { classes, projets } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Container maxWidth="md" className={classes.root} component="section">
      <Container className={classes.container}>
        <Typography variant="h4" component="h2" color="textSecondary">
          {matches ? (
            <Box fontWeight="fontWeightMedium" letterSpacing={4}>
              LUC PEIROLO & ASSOCIÉS
            </Box>
          ) : (
            `LUC PEIROLO & ASSOCIÉS`
          )}
        </Typography>
        <Typography variant="h4" component="h2" color="textSecondary">
          {matches ? (
            <Box fontWeight="fontWeightMedium" letterSpacing={4}>
              ARCHITECTES
            </Box>
          ) : (
            `ARCHITECTES`
          )}
        </Typography>
        <Typography variant="h5" color="textPrimary" className={classes.subtitle}>
          {matches ? (
            <Box fontWeight="fontWeightRegular" letterSpacing={6}>
              NOS PROJETS
            </Box>
          ) : (
            <Box fontWeight="fontWeightRegular" letterSpacing={2}>
              NOS PROJETS
            </Box>
          )}
        </Typography>
        <Grid container className={classes.mainGrid}>
          {projets.map((project) => (
            <ProjetItem key={project.id} project={project} />
          ))}
        </Grid>
      </Container>
      <div className={classes.button}>
        <Button variant="outlined" size="large" color="secondary" component={RouterLink} to="/projets">
            Voir Plus
        </Button>
      </div>
    </Container>
  );
}

Selection.propTypes = {
  classes: PropTypes.object.isRequired,
  projets: PropTypes.array.isRequired
};

export default withStyles(styles)(Selection);
