import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const styles = (theme) => ({
  container: {
    marginBottom: theme.spacing(3),
  },
  header: {
    marginBottom: theme.spacing(1),
  },
});

function Address(props) {
  const { classes } = props;

  return (
    <Container className={classes.container}>
      <Typography variant="h6" className={classes.header}>
        <Box fontWeight="fontWeightMedium" letterSpacing={4}>
          <Link component={RouterLink} to="/contact" color="inherit">
            CONTACTEZ-NOUS
          </Link>
        </Box>
      </Typography>
      <Typography variant="body1" color="textPrimary">
        Luc Peirolo & Associés Architectes
      </Typography>
      <Typography variant="body1" color="textPrimary">
        91/93 rue Thiers - 92100 Boulogne Billancourt - France
      </Typography>
      <Typography variant="body1" color="textPrimary">
        Tél. : + 33 (0)1 46 10 03 97
      </Typography>
    </Container>
  );
}

Address.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Address);
